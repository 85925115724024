<template>
    <div class="hc42Box">
        <div class="hbBox1" v-if="getAdvData(27).children">
            <a :href="getAdvData(27).children[0].url" target="_blank" rel="noopener noreferrer">
                <img :src="getAdvData(27).children[0].image" class="hb1Bg" />
                <div class="hb1Text">
                    <div class="hb1T"><span style="font-size: 48px">01.</span> 什么是微信朋友圈广告？</div>
                    <div class="hb1B">
                        微信朋友圈广告是基于微信公众号生态体系，以类似朋友的原创内容形式在朋友圈中展示的原生广告。<br />
                        用户可以通过点赞、评论等方式进行互动，并依托社交关系链传播，为品牌推广带来加成效应，按曝光次数计费。
                    </div>
                </div>
            </a>
        </div>
        <div class="hbBox2">
            <div class="hb2Box">
                <div class="h2bTop">契合微信朋友圈浏览体验的广告形态</div>
                <div class="h2bImg">
                    <img src="http://iv.okvu.cn/vugw/img/hc4421.png" alt="" class="h2bBg" />
                </div>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Box">
                <div class="h3bTop">可满足您的多元推广目标</div>
                <div class="h3bImg">
                    <img src="http://iv.okvu.cn/vugw/img/hc4431.png" alt="" class="h3bBg" />
                </div>
            </div>
        </div>
        <div class="hbBox4">
            <div class="hb4Box">
                <div class="h4Top">
                    <p class="h4t1">定向技术精准触达目标受众</p>
                    <p class="h4t2">我们为您提供了众多广告定向条件，您可以从中选择适合达成您商业目标的条件锁定人群。</p>
                </div>
                <div class="h4List">
                    <ul>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">地域</p>
                                    <p class="h4b2B">支持按城市定向，数据来源于用户近一个月的常用地点信息</p>
                                </div>
                            </div>
                        </li>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon2" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">性别年龄</p>
                                    <p class="h4b2B">支持按性别定向，支持任选13~60岁中的年龄段定向</p>
                                </div>
                            </div>
                        </li>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon3" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">手机</p>
                                    <p class="h4b2B">支持按手机系统（IOS、Android）定向，支持按联网环境定向</p>
                                </div>
                            </div>
                        </li>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon4" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">婚恋情况</p>
                                    <p class="h4b2B">支持按用户当前婚恋状态定向，包含单身、新婚、已婚、育儿</p>
                                </div>
                            </div>
                        </li>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon5" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">学历</p>
                                    <p class="h4b2B">支持按用户的学历定向，包含博士、硕士、本科、高中、初中、小学</p>
                                </div>
                            </div>
                        </li>
                        <li class="h4lLi">
                            <div class="h4lBox">
                                <div class="h4b1">
                                    <img src="http://iv.okvu.cn/vugw/img/hc4441.png" alt="" class="h4b1Icon6" />
                                </div>
                                <div class="h4b2">
                                    <p class="h4b2T">兴趣</p>
                                    <p class="h4b2B">支持按用户的兴趣定向，包含教育，汽车，金融，房产等</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Box">
                <div class="h5Top">
                    <div class="h5t1">业务展示</div>
                    <div class="h5t2">专业团队，品质服务</div>
                </div>
                <div class="h5List">
                    <ul>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <img src="http://iv.okvu.cn/vugw/img/hc4451.png" alt="" class="h5Icon" />
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">网站建设</p>
                                <p class="h5p2">Website building</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">品牌展示网站</li>
                                    <li class="h53Li">官方门户网站</li>
                                    <li class="h53Li">企业营销网站</li>
                                    <li class="h53Li">电子商务平台</li>
                                    <li class="h53Li">信息门户系统</li>
                                    <li class="h53Li">集团站群系统</li>
                                    <li class="h53Li">3D虚拟仿真</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <img src="http://iv.okvu.cn/vugw/img/hc4452.png" alt="" class="h5Icon" />
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">移动端</p>
                                <p class="h5p2">Mobile end</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">手机网站</li>
                                    <li class="h53Li">APP开发</li>
                                    <li class="h53Li">自适应网站</li>
                                    <li class="h53Li">响应式网站</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <img src="http://iv.okvu.cn/vugw/img/hc4453.png" alt="" class="h5Icon" />
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">小程序</p>
                                <p class="h5p2">Applets</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">O2O场景化</li>
                                    <li class="h53Li">社群社交</li>
                                    <li class="h53Li">生活服务</li>
                                    <li class="h53Li">工具应用</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <img src="http://iv.okvu.cn/vugw/img/hc4454.png" alt="" class="h5Icon" />
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">服务号/订阅号</p>
                                <p class="h5p2">Wechat marketing</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">微官网</li>
                                    <li class="h53Li">微商城</li>
                                    <li class="h53Li">微活动</li>
                                    <li class="h53Li">微预定</li>
                                    <li class="h53Li">微支付</li>
                                </ul>
                            </div>
                        </li>
                        <li class="h5lLi">
                            <div class="h5l1">
                                <img src="http://iv.okvu.cn/vugw/img/hc4455.png" alt="" class="h5Icon" />
                            </div>
                            <div class="h5l2">
                                <p class="h5p1">企业微信</p>
                                <p class="h5p2">Business number</p>
                            </div>
                            <div class="h5l3">
                                <ul>
                                    <li class="h53Li">团队协作</li>
                                    <li class="h53Li">CRM</li>
                                    <li class="h53Li">企业文化</li>
                                    <li class="h53Li">移动办公</li>
                                    <li class="h53Li">行政办公</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hc42Box",
    inject: ["getAdvData"],
};
</script>

<style lang="less" scoped>
.hc42Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        height: 265px;
        position: relative;
        // overflow: hidden;
        & > a {
            display: block;
            width: 100%;
            height: 100%;
            padding-top: 70px;
        }
        .hb1Bg {
            // width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            z-index: -1;
        }
        .hb1Text {
            max-width: 1120px;
            height: 170px;
            margin: 0 auto;
            overflow: hidden;
            color: white;
            .hb1T {
                width: 100%;
                height: 76px;
                line-height: 76px;
                font-size: 30px;
            }
            .hb1B {
                width: 100%;
                height: 60px;
                line-height: 30px;
                margin-top: 20px;
            }
        }
    }
    .hbBox2 {
        width: 100%;
        height: 783px;
        background: #f1f3f8;
        .hb2Box {
            max-width: 1120px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h2bTop {
                width: 100%;
                height: 48px;
                line-height: 48px;
                text-align: center;
                font-size: 30px;
                margin-top: 58px;
            }
            .h2bImg {
                width: 950px;
                height: 680px;
                margin: 0 auto;
                .h2bBg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        height: 639px;
        margin-top: 50px;
        .hb3Box {
            max-width: 1120px;
            height: 100%;
            margin: 0 auto;
            .h3bTop {
                width: 100%;
                height: 48px;
                line-height: 48px;
                text-align: center;
                font-size: 30px;
            }
            .h3bImg {
                width: 985px;
                height: 461px;
                margin: 0 auto;
                margin-top: 40px;
                .h3bBg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .hbBox4 {
        width: 100%;
        height: 570px;
        background: #f1f3f8;
        .hb4Box {
            width: 1120px;
            height: 100%;
            margin: 0 auto;
            overflow: hidden;
            .h4Top {
                width: 100%;
                margin-top: 50px;
                .h4t1 {
                    width: 100%;
                    height: 48px;
                    text-align: center;
                    line-height: 48px;
                    font-size: 30px;
                }
                .h4t2 {
                    width: 100%;
                    height: 23px;
                    line-height: 23px;
                    text-align: center;
                    color: #999;
                    margin-top: 20px;
                }
            }
            .h4List {
                width: 1120px;
                height: 314px;
                margin: 0 auto;
                margin-top: 50px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .h4lLi {
                        width: 360px;
                        height: 140px;
                        border-radius: 10px;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        margin-top: 5px;
                        background-color: #fff;
                        transition: 0.5s;
                        text-align: left;
                        .h4lBox {
                            width: 90%;
                            height: 80%;
                            margin: 0 auto;
                            margin-top: 4%;
                            .h4b1 {
                                width: 60px;
                                height: 60px;
                                margin-top: 30px;
                                margin-left: 10px;
                                float: left;
                                overflow: hidden;
                                position: relative;

                                .h4b1Icon {
                                    position: absolute;
                                    top: -197px;
                                    left: 3px;
                                }
                                .h4b1Icon2 {
                                    position: absolute;
                                    top: -190px;
                                    left: -61px;
                                }
                                .h4b1Icon3 {
                                    position: absolute;
                                    top: -190px;
                                    left: -120px;
                                }
                                .h4b1Icon4 {
                                    position: absolute;
                                    top: -257px;
                                    left: 3px;
                                }
                                .h4b1Icon5 {
                                    position: absolute;
                                    top: -257px;
                                    left: -61px;
                                }
                                .h4b1Icon6 {
                                    position: absolute;
                                    top: -260px;
                                    left: -122px;
                                }
                            }

                            .h4b2 {
                                width: 230px;
                                height: 100%;
                                float: left;
                                margin-left: 20px;
                                .h4b2T {
                                    width: 100%;
                                    height: 32px;
                                    line-height: 32px;
                                    font-size: 20px;
                                    margin-top: 20px;
                                }
                                .h4b2B {
                                    width: 100%;
                                    height: 40px;
                                    line-height: 20px;
                                    font-size: 12px;
                                    color: #999999;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                    .h4lLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        height: 770px;
        background: #f1f1f1;
        .hb5Box {
            width: 1423px;
            height: 100%;
            overflow: hidden;
            margin: 0 auto;
            .h5Top {
                width: 100%;
                text-align: center;
                margin-top: 50px;
                .h5t1 {
                    width: 100%;
                    height: 36px;
                    line-height: 36px;
                    font-size: 36px;
                }
                .h5t2 {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 15px;
                    color: #999999;
                }
            }
            .h5List {
                width: 1399px;
                height: 560px;
                margin: 0 auto;
                margin-top: 20px;
                ul {
                    width: 100%;
                    height: 100%;
                    .h5lLi {
                        width: 20%;
                        height: 100%;
                        text-align: center;
                        float: left;
                        background-color: #fff;
                        transition: 0.3s;
                        position: relative;
                        .h5l1 {
                            width: 100%;
                            height: 86px;
                            margin-top: 50px;
                            .h5Icon {
                                width: 92px;
                                height: 100%;
                                transition: 0.3s;
                            }
                        }
                        .h5l2 {
                            width: 100%;
                            margin-top: 20px;
                            transition: 0.3s;
                            .h5p1 {
                                width: 100%;
                                height: 24px;
                                line-height: 24px;
                                font-size: 24px;
                            }
                            .h5p2 {
                                width: 100%;
                                height: 15px;
                                line-height: 15px;
                                font-size: 15px;
                                color: #dddddd;
                                margin-top: 10px;
                            }
                        }
                        .h5l3 {
                            width: 78px;
                            min-height: 34px;
                            margin: 0 auto;
                            margin-top: 50px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-around;
                                flex-wrap: wrap;
                                .h53Li {
                                    width: 100%;
                                    height: 34px;
                                    line-height: 34px;
                                    text-align: center;
                                    list-style-type: disc;
                                    color: #585858;
                                    font-size: 13px;
                                    cursor: pointer;
                                }
                                .h53Li:hover {
                                    color: #999999;
                                }
                            }
                        }
                    }
                    .h5lLi:nth-child(n + 2) {
                        border-left: 1px solid #f6f6f6;
                        box-sizing: border-box;
                    }
                    .h5lLi:hover {
                        z-index: 10;
                        box-shadow: 0 0 59px rgba(0, 0, 0, 0.07);
                        .h5Icon {
                            transform: scale(1.1);
                        }
                        .h5l2 {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
    }
}
</style>
